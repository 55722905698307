import { useTranslation } from '@/components';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import { LocalStorageKeys } from '@/enums';
import { addAlphaToHex, fontWeights } from '@/helpers';
import ThanksSvg from '@/images/thanks';
import { useAppContext } from '@contexts/app-context';
import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const SurveyThanks = () => {
  const { setMainClasses } = useAppContext();
  const t = useTranslation('SurveyThanks');
  const mainTheme = useTheme();
  const thanksData = localStorage.getItem(LocalStorageKeys.SURVEY_THANKS_DATA);
  const surveyThanksData =
    (thanksData &&
      (JSON.parse(thanksData) as {
        topic: string;
        customizations?: CompanyCustomizations;
      })) ||
    undefined;

  useEffect(() => {
    setMainClasses('main-flex');
    // eslint-disable-next-line
  }, []);

  const startUrl = () => {
    const currentTopic = localStorage.getItem(
      LocalStorageKeys.SURVEY_THANKS_DATA
    );
    const defaultUrl = process.env.REACT_APP_FRIEND_START;
    if (!defaultUrl || defaultUrl.trim() === '') {
      if (!currentTopic) {
        return process.env.REACT_APP_LANDING;
      }

      return `${process.env.REACT_APP_LANDING}/topics/${currentTopic}/`;
    }

    return defaultUrl;
  };

  const render = (theme: Theme) => (
    <>
      <Helmet>
        <meta name='theme-color' content='#0071E3' />
        <style>
          {`${theme.breakpoints.up('sm')} {
                  body {
                    background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                      theme.palette.secondary.main,
                      10
                    )}), url('/assets/images/bgOpenEnded.png');
                    background-size: 50% 100%, 50% 100%;
                    background-repeat: no-repeat, no-repeat;
                    background-position: left center, right center;
                  }
                }
                ${theme.breakpoints.down('sm')} {
                  body {
                    background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                      theme.palette.secondary.main,
                      10
                    )});
                  }
                }`}
        </style>
      </Helmet>
      <Box
        width={{
          sm: '50%'
        }}
      >
        <Box
          bgcolor={{
            xs: '#f5f5f5',
            sm: 'white'
          }}
          borderRadius='24px'
          boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
          p={1}
          pt={3}
          textAlign='center'
          my='auto'
          mx={{
            xs: 1,
            sm: 'calc((100% - 370px) / 2)'
          }}
        >
          <ThanksSvg />
          <Box mt={1} textAlign='center' pb={3} px={4}>
            <Typography
              variant='h3'
              fontSize='20px'
              fontWeight={fontWeights.extraBold}
              color='#414042'
            >
              {t('ThankYou')}
            </Typography>
            <Typography
              variant='body1'
              mt='12px'
              fontSize='16px'
              textAlign='center'
              color='#6D6E71'
            >
              {surveyThanksData &&
              surveyThanksData.customizations &&
              surveyThanksData.customizations.feedbackFinishedReceived
                ? surveyThanksData.customizations.feedbackFinishedReceived
                : t('ReceivedInputs')}
            </Typography>
            <Typography
              variant='body1'
              mt={4}
              fontWeight={fontWeights.semiBold}
              textAlign='center'
              fontSize='20px'
              color='#414042'
            >
              {surveyThanksData &&
              surveyThanksData.customizations &&
              surveyThanksData.customizations.feedbackFinishedInvite
                ? surveyThanksData.customizations.feedbackFinishedInvite
                : t('GetForYourself')}
            </Typography>
            <Button
              sx={{
                mt: 2.6
              }}
              fullWidth
              component={Link}
              to={startUrl()}
              variant='contained'
              color='primary'
            >
              {t('$Start')}
            </Button>
            <Box
              component='small'
              fontStyle='italic'
              textAlign='center'
              mt={1.4}
              display='block'
              color='#6D6E71'
            >
              {t('ClickToStart')}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  if (
    surveyThanksData &&
    surveyThanksData.customizations &&
    (surveyThanksData.customizations.primaryColor ||
      surveyThanksData.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={surveyThanksData.customizations}>
        {render}
      </CustomizedThemeProvider>
    );
  }

  return render(mainTheme);
};

export default SurveyThanks;
