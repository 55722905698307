import { Markdown, useTranslation } from '@/components';
import { RectifyType } from '@/enums';
import { addAlphaToHex, fontFamilies, fontWeights, webClient } from '@/helpers';
import {
  ErrorOutline,
  Keyboard,
  KeyboardVoiceOutlined,
  Mic,
  West
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { ReactElement, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ReactMic } from 'react-mic';

const dataMinLength = 20;

interface IProps {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  itemId: string;
  optionId: string;
  topicId?: string;
  isFeedback: boolean;
  name?: string;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
}

const SlideInBox = ({
  children,
  show
}: {
  children: ReactElement;
  show: boolean;
}) => {
  return (
    <Box
      overflow='hidden'
      maxHeight={show ? 30 : 0}
      sx={{
        transition: 'all 1s ease-in-out'
      }}
    >
      {children}
    </Box>
  );
};

export default function OpenEnded({
  submit,
  itemId,
  optionId,
  code,
  project,
  topic,
  topicId,
  isFeedback,
  name
}: IProps) {
  const theme = useTheme();
  const t = useTranslation('Feedback.OpenEnded');

  const [inputTypeSelection, setInputTypeSelection] = useState<
    'text' | 'voice'
  >('text');
  const [inputType, setInputType] = useState<('text' | 'voice') | undefined>();
  const [data, setData] = useState<Blob | string | undefined>();
  const [result, setResult] = useState<
    RectifyVoiceResponse | RectifyResponse | undefined
  >();
  const [recordedTime, setRecordedTime] = useState(0);
  const [rectifierHistory, setRectifierHistory] = useState<string[]>([]);
  const [lastSummary, setLastSummary] = useState<string | undefined>();
  const [showMakeBetter, setShowMakeBetter] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudioURL, setRecordedAudioURL] = useState<string>();
  const recordedData = useRef<Blob[]>([]);
  const recordingStart = useRef<number>(new Date().getTime());

  const getMinimumNeed = () => {
    if (lastSummary) {
      return 1e4;
    }

    return 6e4;
  };

  const getPerfectNeed = () => {
    if (lastSummary) {
      return 6e4;
    }

    return 15e4;
  };

  const stringData = () =>
    !data || typeof data !== 'string' ? '' : (data as string).trim();
  const blobData = () => data as Blob;

  const submitResult = async () => {
    if (!result || !result.canSubmit) {
      return;
    }

    await submit([
      {
        itemId,
        optionId,
        value: result.content
      }
    ]);
  };

  const getHeadline = () => {
    if (isFeedback) {
      if (topic.othersHeadline) {
        return topic.othersHeadline.replace('{name}', name!);
      }

      return t('HowDoYouSeeIn', {
        name,
        topic: topic.name
      }).toTsx();
    }

    if (topic.selfHeadline) {
      return topic.selfHeadline;
    }

    return t('HowDoYouSeeIn', {
      name: t('$Feedback.Yourself'),
      topic: topic.name
    }).toTsx();
  };

  const rectify = async () => {
    const reference = topicId || code || project;
    if (!reference) {
      alert('Reference not found!\nPlease refresh the page!');
      return;
    }

    if (!data) {
      return;
    }

    if (!inputType) {
      return;
    }

    const res =
      inputType === 'voice'
        ? await webClient.api.tools.rectifierVoice(blobData(), {
            type: RectifyType.SURVEY_RESPONSE,
            reference,
            history: rectifierHistory.map((x) => ({
              role: 'user',
              content: x
            }))
          })
        : await webClient.api.tools.rectifier({
            input: stringData(),
            type: RectifyType.SURVEY_RESPONSE,
            reference,
            history: rectifierHistory.map((x) => ({
              role: 'user',
              content: x
            }))
          });

    setResult(res.data.data);
    setRectifierHistory((x) => [
      ...x,
      ...[
        'input' in res.data.data
          ? (res.data.data.input as string)
          : stringData()
      ]
    ]);
    setLastSummary(res.data.data.content);
  };

  const onSoundRecorderClick = () => {
    if (!isRecording) {
      recordingStart.current = new Date().getTime();
      recordedData.current = [];
      setRecordedAudioURL(undefined);
    }

    setIsRecording((x) => !x);
  };

  const renderVoiceInput = () => (
    <Box>
      <Box display='flex' flexDirection='row' alignItems='center' mt={2}>
        <Button
          variant={isRecording ? 'contained' : 'outlined'}
          onClick={onSoundRecorderClick}
          sx={{
            borderColor: 'white',
            borderRadius: '12px',
            boxShadow:
              '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
            '&:hover': {
              bgcolor: isRecording ? undefined : theme.palette.primary.main,
              color: theme.palette.primary.contrastText
            }
          }}
        >
          {isRecording ? (
            recordedTime < getMinimumNeed() ? (
              <CircularProgress
                variant='determinate'
                value={
                  recordedTime >= getMinimumNeed()
                    ? 100
                    : (recordedTime / getMinimumNeed()) * 100
                }
                size={24}
                color='info'
                sx={{
                  mr: 1,
                  color: 'white'
                }}
              />
            ) : (
              <Box mr={1}>
                {Math.floor(recordedTime / 60000).toFixedLength(2)}:
                {Math.floor((recordedTime / 1000) % 60).toFixedLength(2)}
              </Box>
            )
          ) : (
            <KeyboardVoiceOutlined />
          )}
          {isRecording
            ? 'Finish'
            : recordedData.current.length > 0
            ? 'Start Over'
            : 'Start'}
        </Button>
        <Box flex='auto 1 1' height={40} overflow='hidden'>
          <Typography
            textAlign='center'
            fontSize={14}
            fontWeight={fontWeights.light}
            color={addAlphaToHex('#414042', 70)}
            position='absolute'
            display='flex'
            flexDirection='column'
            width='100%'
            bottom={0}
          >
            <SlideInBox show={recordedTime >= 2e3}>
              <Markdown>{t('GoodStartKeepItUp')}</Markdown>
            </SlideInBox>
            <SlideInBox show={recordedTime >= 1e4}>
              <Markdown>
                {t('TalkForAtleast', { sec: getMinimumNeed() / 1000 })}
              </Markdown>
            </SlideInBox>
            <SlideInBox show={recordedTime >= getMinimumNeed()}>
              <Markdown>{t('MoreDetailsMoreValue')}</Markdown>
            </SlideInBox>
            <SlideInBox show={recordedTime >= getPerfectNeed()}>
              <Markdown>{t('PerfectClickSendToProceed')}</Markdown>
            </SlideInBox>
          </Typography>
          <Box
            position='absolute'
            left={0}
            right={0}
            top={0}
            bottom={0}
            sx={{
              background:
                'linear-gradient(to bottom, white, transparent, transparent)'
            }}
          />
        </Box>
      </Box>
      <Box height={0} overflow='hidden'>
        <ReactMic
          record={isRecording}
          onData={() => {
            setRecordedTime(
              Math.round(new Date().getTime() - recordingStart.current)
            );
          }}
          onStop={(e) => {
            setIsRecording(false);
            setData(e.blob);
            setRecordedAudioURL(e.blobURL);
          }}
          backgroundColor='#0000'
          strokeColor='#0000'
          echoCancellation
        />
      </Box>
      {recordedAudioURL && (
        <Box mt={2}>
          <audio
            src={recordedAudioURL}
            controls
            onError={() => setRecordedAudioURL(undefined)}
          />
        </Box>
      )}
      {blobData() && recordedTime < getMinimumNeed() && (
        <Box display='flex' flexDirection='row' mt={32 / 12}>
          <ErrorOutline
            color='error'
            sx={{
              mr: 1
            }}
          />
          <Typography
            fontSize={14}
            fontWeight={fontWeights.light}
            color={theme.palette.error.main}
          >
            Sorry but this was shorter than needed.
            <br />
            Please start over and make sure to talk for at least{' '}
            {getMinimumNeed() / 1000} seconds.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderTextInput = () => (
    <Box mt={2} display='flex' flexDirection='column'>
      <TextField
        variant='outlined'
        type='text'
        name='comment'
        multiline
        value={data}
        placeholder={`Write at least ${dataMinLength} characters`}
        onChange={(e) => setData(e.target.value)}
        sx={{
          '& .MuiInputBase-root': {
            bgcolor: 'white',
            border: 'solid 1px #D1D3D4',
            color: '#41404c'
          }
        }}
      />
      <Box textAlign='end' mt={1}>
        <Box
          component='span'
          color={
            stringData().length < dataMinLength
              ? theme.palette.error.main
              : theme.palette.success.main
          }
        >
          {stringData().length}
        </Box>
        <Box component='span' color={theme.palette.primary.main}>
          /{dataMinLength}
        </Box>
      </Box>
    </Box>
  );

  const cantSubmit = () => {
    if (inputType === 'text') {
      return stringData().length < dataMinLength;
    }

    if (inputType === 'voice') {
      return recordedTime < getMinimumNeed();
    }

    return false;
  };

  if (result) {
    return (
      <>
        <Helmet>
          <meta name='theme-color' content='#0071E3' />
          <style>
            {`${theme.breakpoints.up('sm')} {
              body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.secondary.main,
                  10
                )}), url('/assets/images/bgOpenEnded.png');
                background-size: 50% 100%, 50% 100%;
                background-repeat: no-repeat, no-repeat;
                background-position: left center, right center;
              }
            }
            ${theme.breakpoints.down('sm')} {
              body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.secondary.main,
                  10
                )});
              }
            }`}
          </style>
        </Helmet>
        <Box
          width={{
            sm: '50%'
          }}
        >
          <Dialog
            open={showMakeBetter}
            onClose={() => setShowMakeBetter(false)}
          >
            <DialogTitle>How do you prefer to continue?</DialogTitle>
            <DialogContent>
              <Button
                fullWidth
                onClick={() => {
                  setShowMakeBetter(false);
                  setResult(undefined);
                }}
              >
                {t('$Diagnose.ImproveCurrentVersion')}
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setShowMakeBetter(false);
                  setResult(undefined);
                  setRectifierHistory([]);
                  setLastSummary(undefined);
                }}
                sx={{
                  textAlign: 'start'
                }}
              >
                {t('$Diagnose.MakeNewVersionFromScratch')}
              </Button>
            </DialogContent>
          </Dialog>
          <Box
            display='flex'
            flexDirection='column'
            mx={{
              xs: 1,
              sm: 'calc((100% - 520px) / 2)'
            }}
          >
            <Hidden smUp>
              <Box
                borderRadius='24px'
                width='100%'
                height='calc((100vw - 64px) * 226 / 328)'
                position='relative'
                mb={16 / 12}
                sx={{
                  backgroundImage: "url('/assets/images/bgOpenEnded.png')",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center 30%'
                }}
              />
            </Hidden>
            <Box
              borderRadius='24px'
              boxShadow={`0 0 10px 2px ${addAlphaToHex(
                theme.palette.primary.main,
                10
              )}`}
              display='flex'
              flexDirection='column'
              p={{
                xs: 16 / 12,
                sm: 32 / 12
              }}
              bgcolor='#FFF7'
            >
              <Typography
                fontSize={{
                  xs: 16,
                  sm: 24
                }}
                fontWeight={fontWeights.bold}
                fontFamily={fontFamilies.LibreBaskerville}
                color='#414042'
              >
                {t('ThisIsSummaryOfWhatYouShared')}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={fontWeights.extraLight}
                fontStyle='italic'
                color='#414042'
                mt={1}
              >
                <Markdown>{result.content.clear()}</Markdown>
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                disabled={!result.canSubmit}
                onClick={submitResult}
              >
                {t('$Submit')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                color='primary'
                onClick={() => setShowMakeBetter(true)}
                sx={{
                  ml: 16 / 12,
                  borderColor: 'white',
                  bgcolor: 'white',
                  '&:hover': {
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                  }
                }}
              >
                {t('$MakeItBetter')}
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (inputType) {
    return (
      <>
        <Helmet>
          <meta name='theme-color' content='#0071E3' />
          <style>
            {`${theme.breakpoints.up('sm')} {
              body {
                background-image: url('/assets/images/bgOpenEnded.png');
                background-size: 50% 100%;
                background-repeat: no-repeat;
                background-position: left center;
              }
            }
            ${theme.breakpoints.down('sm')} {
              body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.secondary.main,
                  10
                )});
                background-repeat: no-repeat;
              }
            }`}
          </style>
        </Helmet>
        <Box
          display='flex'
          flexDirection={{
            xs: 'column',
            sm: 'row'
          }}
        >
          <Box
            flex={{
              sm: '50% 0 0'
            }}
            maxWidth={{
              sm: '50%'
            }}
            height={{
              sm: '100%'
            }}
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <Hidden smDown={!!lastSummary}>
              <Box
                borderRadius='24px'
                bgcolor={{
                  sm: '#0009'
                }}
                color='#fff'
                p={{
                  sm: 32 / 12
                }}
                width={{
                  xs: '100%',
                  sm: '520px'
                }}
                height={{
                  xs: 'calc((100vw - 64px) * 226 / 328)',
                  sm: 'auto'
                }}
                position='relative'
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    backgroundImage: "url('/assets/images/bgOpenEnded.png')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center 30%'
                  }
                }}
              >
                <Hidden smUp={!!lastSummary}>
                  <Typography
                    fontSize={{
                      xs: 16,
                      sm: 20
                    }}
                    p={{
                      xs: 16 / 12,
                      sm: 0
                    }}
                    borderRadius={{
                      xs: '0 0 24px 24px',
                      sm: 0
                    }}
                    position={{
                      xs: 'absolute',
                      sm: 'static'
                    }}
                    bottom={{
                      xs: 0,
                      sm: 'unset'
                    }}
                    fontWeight={fontWeights.regular}
                    fontFamily={fontFamilies.LibreBaskerville}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        background:
                          'linear-gradient(to bottom, transparent, #0009)',
                        width: '100%'
                      }
                    }}
                  >
                    {getHeadline()}
                  </Typography>
                </Hidden>
                <Hidden smDown>
                  {lastSummary ? (
                    <>
                      <Typography
                        variant='h5'
                        fontSize={24}
                        fontWeight={fontWeights.bold}
                        fontFamily={fontFamilies.LibreBaskerville}
                        mb={16 / 12}
                      >
                        {t('ThisIsSummaryOfWhatYouShared')}
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={fontWeights.extraLight}
                        fontStyle='italic'
                        component='pre'
                        whiteSpace='normal'
                      >
                        <Markdown>{lastSummary}</Markdown>
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      fontSize={16}
                      fontWeight={fontWeights.regular}
                      mt={16 / 12}
                    >
                      <Markdown>{topic.items[0].name.clear()}</Markdown>
                    </Typography>
                  )}
                </Hidden>
              </Box>
            </Hidden>
            <Hidden smUp>
              {!lastSummary && (
                <Box
                  mt={32 / 12}
                  borderRadius='24px'
                  boxShadow={`0 0 10px 2px ${addAlphaToHex(
                    theme.palette.secondary.main,
                    10
                  )}`}
                  p={16 / 12}
                  bgcolor='white'
                  fontSize={14}
                  fontWeight={fontWeights.regular}
                  width='100%'
                  mb={2}
                >
                  <Markdown>{topic.items[0].name.clear()}</Markdown>
                </Box>
              )}
            </Hidden>
          </Box>
          <Box
            flex={{
              sm: '50% 0 0'
            }}
            maxWidth={{
              sm: '50%'
            }}
          >
            <Box
              mx={{
                xs: 1,
                sm: 'calc((100% - 530px) / 2)'
              }}
            >
              <Button onClick={() => setInputType(undefined)}>
                <West
                  sx={{
                    mr: 1
                  }}
                />
                Back
              </Button>
              <Hidden smUp>
                {lastSummary && (
                  <Box
                    mt={32 / 12}
                    borderRadius='24px'
                    boxShadow={`0 0 10px 2px ${addAlphaToHex(
                      theme.palette.secondary.main,
                      10
                    )}`}
                    p={16 / 12}
                    bgcolor='white'
                    width='100%'
                    mb={2}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={fontWeights.bold}
                      fontFamily={fontFamilies.LibreBaskerville}
                      mb={1}
                    >
                      {t('ThisIsSummaryOfWhatYouShared')}
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={fontWeights.extraLight}
                      fontStyle='italic'
                      component='pre'
                      whiteSpace='normal'
                    >
                      <Markdown>{lastSummary}</Markdown>
                    </Typography>
                  </Box>
                )}
              </Hidden>
              <Typography
                fontSize={20}
                fontWeight={fontWeights.regular}
                fontFamily={fontFamilies.LibreBaskerville}
                mt={2}
              >
                {lastSummary
                  ? 'Do you want to add something?'
                  : 'Be specific and share real examples'}
              </Typography>
              {inputType === 'voice' ? renderVoiceInput() : renderTextInput()}
              <Button
                variant='contained'
                color='primary'
                disabled={cantSubmit()}
                onClick={rectify}
                sx={{
                  mt: 2,
                  alignSelf: 'start',
                  px: 2
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name='theme-color' content='#0071E3' />
        <style>
          {`${theme.breakpoints.up('sm')} {
            body {
              background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                theme.palette.secondary.main,
                10
              )}), url('/assets/images/bgOpenEnded.png');
              background-size: 50% 100%, 50% 100%;
              background-repeat: no-repeat, no-repeat;
              background-position: left center, right center;
            }
          }
          ${theme.breakpoints.down('sm')} {
            body {
              background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                theme.palette.secondary.main,
                10
              )});
              background-repeat: no-repeat;
            }
          }`}
        </style>
      </Helmet>
      <Box
        width={{
          sm: '50%'
        }}
      >
        <Box
          mx={{
            xs: 1,
            sm: 'calc((100% - 530px) / 2)'
          }}
        >
          <Hidden smUp>
            <Box
              sx={{
                backgroundImage: "url('/assets/images/bgOpenEnded.png')",
                backgroundSize: 'cover'
              }}
              height='calc((100vw - 64px) * 226 / 328)'
              borderRadius='24px'
              position='relative'
            >
              <Typography
                fontSize={16}
                fontWeight={fontWeights.regular}
                position='absolute'
                borderRadius='0 0 24px 24px'
                left={0}
                right={0}
                bottom={0}
                p={16 / 12}
                color='white'
                fontFamily={fontFamilies.LibreBaskerville}
                sx={{
                  background: 'linear-gradient(to bottom, transparent, #0009)'
                }}
              >
                {getHeadline()}
              </Typography>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Typography
              fontSize={20}
              fontWeight={fontWeights.regular}
              fontFamily={fontFamilies.LibreBaskerville}
            >
              {getHeadline()}
            </Typography>
          </Hidden>
          <Box
            mt={32 / 12}
            borderRadius='24px'
            boxShadow={`0 0 10px 2px ${addAlphaToHex(
              theme.palette.secondary.main,
              10
            )}`}
            p={{
              xs: 16 / 12,
              sm: 32 / 12
            }}
            bgcolor='white'
            fontSize={{
              xs: 14,
              sm: 16
            }}
            fontWeight={fontWeights.regular}
          >
            <Markdown>{topic.items[0].name.clear()}</Markdown>
          </Box>
          <Typography
            mt={{
              xs: 32 / 12,
              sm: 4
            }}
            fontSize={{
              xs: 14,
              sm: 16
            }}
            fontWeight={fontWeights.light}
          >
            Take a moment to think it through before you start recording. Think
            about specific behaviors and examples
          </Typography>
          <Typography
            fontSize={{
              xs: 14,
              sm: 16
            }}
            fontWeight={fontWeights.light}
          >
            Choose if you prefer to talk or write.
          </Typography>
          <Box
            mt={40 / 12}
            display='flex'
            flexDirection='row'
            sx={{
              '&>*': {
                border: 'solid 1px #333333',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: fontWeights.semiBold,
                py: '8px',
                px: '24px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: addAlphaToHex(theme.palette.secondary.main, 10)
                },
                '&:first-of-type': {
                  borderRight: 'none'
                },
                '&.active': {
                  bgcolor: addAlphaToHex(theme.palette.secondary.main, 20)
                }
              }
            }}
          >
            <Box
              borderRadius='24px 0 0 24px'
              onClick={() => setInputTypeSelection('voice')}
              className={inputTypeSelection === 'voice' ? 'active' : undefined}
            >
              <Mic />
              {t('Talk')}
            </Box>
            <Box
              borderRadius='0 24px 24px 0'
              onClick={() => setInputTypeSelection('text')}
              className={inputTypeSelection === 'text' ? 'active' : undefined}
            >
              <Keyboard />
              {t('Write')}
            </Box>
          </Box>
          <Button
            sx={{
              mt: 2,
              px: 3
            }}
            variant='contained'
            onClick={() => setInputType(inputTypeSelection)}
          >
            Start
          </Button>
        </Box>
      </Box>
    </>
  );
}
