import { useTranslation } from '@/components';
import { useReportContext } from '@/contexts/report-context';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { Check } from '@mui/icons-material';
import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { ItemProgress, StyledTable } from '@survey/report/components';

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  rows: TableAnonymousItemResponse[];
}

const TableAnonymous = ({ id, options, rows }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const { tableQuestionAverageDisabled } = useReportContext();
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  const total = (rows: TableAnonymousItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, 'NA');
  };

  const totalBenchmark = (rows: TableAnonymousItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, 'NA');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          {options.map((y) => (
            <TableCell
              key={`item_${id}_option_${y.id}_title`}
              component='th'
              width='1%'
            >
              {y.title}
            </TableCell>
          ))}
          <TableCell component='th' width='1%'>
            {t('Average')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell
                component='th'
                width='1%'
                sx={{
                  whiteSpace: {
                    sm: 'nowrap'
                  }
                }}
              >
                {t('CohortBenchmark')}
              </TableCell>
              <TableCell
                component='th'
                width='1%'
                sx={{
                  whiteSpace: {
                    sm: 'nowrap'
                  }
                }}
              >
                {t('YoursVSOthers')}
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component='th'>{t('SelfRating')}</TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_self_option_${x.id}_value`}>
              {rows.any((y) => y.owner && y.option.value === x.value) && (
                <Check />
              )}
            </TableCell>
          ))}
          <TableCell>
            {rows
              .filter((x) => x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, 'NA')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell>
                {rows
                  .filter((x) => x.owner)
                  .average((x) => x.benchmark || 0)
                  .toFixedString(1, 'NA')}
              </TableCell>
              <TableCell sx={{ p: '4px 8px' }}>
                <ItemProgress
                  user={rows
                    .filter((x) => x.owner && !x.option.optional)
                    .average((x) => x.option.value!)}
                  other={rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          <TableCell component='th'>{t('Others')}</TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_others_option_${x.id}_value`}>
              {rows.any((y) => !y.owner && y.option.value === x.value) && (
                <Check />
              )}
            </TableCell>
          ))}
          <TableCell>
            {rows
              .filter((x) => !x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, 'NA')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell>
                {rows
                  .filter((x) => !x.owner)
                  .average((x) => x.benchmark || 0)
                  .toFixedString(1, 'NA')}
              </TableCell>
              <TableCell sx={{ p: '4px 8px' }}>
                <ItemProgress
                  user={rows
                    .filter((x) => !x.owner && !x.option.optional)
                    .average((x) => x.option.value!)}
                  other={rows
                    .filter((x) => !x.owner)
                    .average((x) => x.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
      </TableBody>
      {!tableQuestionAverageDisabled && (
        <TableFooter>
          <TableRow
            sx={{
              '&>td': {
                fontSize: '16px',
                fontWeight: fontWeights.bold
              }
            }}
          >
            <TableCell />
            <TableCell
              colSpan={options.length}
              sx={{
                boxShadow: 'none !important',
                textAlign: 'center',
                bgcolor: lightenHexColor(theme.palette.secondary.main, 80),
                p: '6px',
                color: getTextColor(
                  lightenHexColor(theme.palette.secondary.main, 80)
                )
              }}
            >
              <Box fontWeight={fontWeights.semiBold}>
                {t('QuestionAverage')}
              </Box>
              <Box
                fontWeight={fontWeights.light}
                fontSize={{
                  xs: '10px',
                  sm: '11px',
                  md: '13px'
                }}
              >
                ({t('ExcludingSelfRating')})
              </Box>
            </TableCell>
            <TableCell>{total(rows)}</TableCell>
            {hasBenchmark && (
              <>
                <TableCell>{totalBenchmark(rows)}</TableCell>
                <TableCell />
              </>
            )}
          </TableRow>
        </TableFooter>
      )}
    </StyledTable>
  );
};

export default TableAnonymous;
