import { getLanguage } from '@/components';
import React, { ReactElement } from 'react';

String.prototype.clear = function (): string {
  const markdown = '```markdown';
  let res = this.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
  const markdownIndex = res.indexOf(markdown);

  if (markdownIndex > -1) {
    const lastCodeEnding = res.lastIndexOf('```');
    res = res.substring(markdownIndex + markdown.length, lastCodeEnding);
  }

  return res;
};

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.toTsx = function (): React.ReactNode[] {
  const supportedTags = [
    'ul',
    'ol',
    'li',
    'strong',
    'code',
    'span',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6'
  ];
  const supportedEndTags = [
    '/ul',
    '/ol',
    '/li',
    '/strong',
    '/code',
    '/span',
    '/h1',
    '/h2',
    '/h3',
    '/h4',
    '/h5',
    '/h6'
  ];

  let temp = this;
  if (/^"(.*)"$/.test(temp)) {
    temp = temp.replace(/^"(.*)"$/, '$1');
  }

  temp = temp
    .replace(/\\n/g, '\n')
    .replace(/\\t/g, '\t')
    .replace(/\\"/g, '"')
    .replace(/```([^`]+)```/g, '<code>$1</code>')
    .replace(/\*{2}([^*]+)\*{2}/g, '<h3>$1</h3>');
  const strs = temp.split(/\r?\n/g);
  const res = [];
  const elems: ReactElement[] = [];
  let elem: ReactElement | null = null;
  // eslint-disable-next-line no-unused-vars
  const elemCondition = (s: string) => true;
  let elemKey = 1;
  for (let i = 0; i < strs.length; i++) {
    const s = strs[i]
      .replace(/\s(\s+)/g, (x, y) => x[0] + y.replace(/\s/g, '\u00a0'))
      .replace(/###\s(.+)/g, '<h2>$1</h2>');

    if (/^[-@*_=]+$/.test(s)) {
      continue;
    }

    if (i > 0 && i < strs.length) {
      if (elem && elemCondition(s)) {
        elem.props.children.push(
          React.createElement('br', { key: 'newLine' + i })
        );
      } else {
        res.push(React.createElement('br', { key: 'newLine' + i }));
      }
    }

    const tags = s.split(/[<>]/g);
    for (const s1 of tags) {
      if (s1.trim() === '') {
        continue;
      }

      if (supportedTags.indexOf(s1.toLowerCase()) > -1) {
        elem = React.createElement(s1, { key: 'tag' + elemKey }, []);
        elemKey++;
        const index = elems.push(elem);
        if (index > 1) {
          elems[index - 2].props.children.push(elem);
        } else if (index === 1) {
          res.push(elem);
        }
        continue;
      }

      if (supportedEndTags.indexOf(s1.toLowerCase()) > -1) {
        elems.pop();
        elem = elems.length > 0 ? elems[elems.length - 1] : null;
        continue;
      }

      if (elem) {
        elem.props.children.push(s1);
      } else {
        res.push(s1);
      }
    }
  }
  return res;
};

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.subString = function (
  this: string,
  start: number,
  end?: number
) {
  if (!end || end > 0) {
    return this.substring(start, end);
  }

  return this.substring(start, this.length + end);
};

String.prototype.getCookie = function (name: string) {
  const cookies = document.cookie.split(';').map((x) => x.trim().split('='));

  return cookies.find((x) => x[0] === name)?.at(1) || null;
};

String.prototype.matchAllArray = function (this: string, pattern: RegExp) {
  const matches = this.matchAll(pattern);
  const res: RegExpMatchArray[] = [];
  let match: IteratorResult<RegExpMatchArray, any>;

  while (!(match = matches.next()).done) {
    res.push(match.value);
  }

  return res;
};

String.prototype.asDateTime = function (this: string, format?: string) {
  format = format || 'yyyy/MM/dd - HH:mm:ss';
  try {
    const lang = getLanguage();
    const date = new Date(this);

    format = format.replace(
      /yyyy/g,
      date.toLocaleString(lang, {
        year: 'numeric'
      })
    );
    format = format.replace(
      /yy/g,
      date.toLocaleString(lang, {
        year: '2-digit'
      })
    );
    format = format.replace(
      /MMMM/g,
      date.toLocaleString(lang, {
        month: 'long'
      })
    );
    format = format.replace(
      /MMM/g,
      date.toLocaleString(lang, {
        month: 'short'
      })
    );
    format = format.replace(
      /MM/g,
      date.toLocaleString(lang, {
        month: '2-digit'
      })
    );
    format = format.replace(
      /\bM\b/g,
      date.toLocaleString(lang, {
        month: 'numeric'
      })
    );
    format = format.replace(
      /dddd/g,
      date.toLocaleString(lang, {
        weekday: 'long'
      })
    );
    format = format.replace(
      /ddd/g,
      date.toLocaleString(lang, {
        weekday: 'short'
      })
    );
    format = format.replace(
      /dd/g,
      date.toLocaleString(lang, {
        day: '2-digit'
      })
    );
    format = format.replace(
      /\bd\b/g,
      date.toLocaleString(lang, {
        day: 'numeric'
      })
    );
    format = format.replace(
      /HH/g,
      date.toLocaleString(lang, {
        hour: '2-digit',
        hour12: false
      })
    );
    format = format.replace(
      /\bH\b/g,
      date.toLocaleString(lang, {
        hour: 'numeric',
        hour12: false
      })
    );
    format = format.replace(
      /hh/g,
      date.toLocaleString(lang, {
        hour: '2-digit',
        hour12: true
      })
    );
    format = format.replace(
      /\bh\b/g,
      date.toLocaleString(lang, {
        hour: 'numeric',
        hour12: true
      })
    );
    format = format.replace(
      /mm/g,
      date.toLocaleString(lang, {
        minute: '2-digit'
      })
    );
    format = format.replace(
      /\bm\b/g,
      date.toLocaleString(lang, {
        minute: 'numeric'
      })
    );
    format = format.replace(
      /ss/g,
      date.toLocaleString(lang, {
        second: '2-digit'
      })
    );
    format = format.replace(
      /\bs\b/g,
      date.toLocaleString(lang, {
        second: 'numeric'
      })
    );

    return format;
  } catch (exp) {
    console.log(exp);
    return this;
  }
};

String.prototype.emptyAsUndefined = function (): string | undefined {
  if (!this || this.trim().length === 0) {
    return undefined;
  }

  return this.trim();
};

String.prototype.fixLength = function (
  this: string,
  length: number,
  repeater: string
) {
  let temp = this;
  while (temp.length < length) {
    temp = `${repeater}${temp}`;
  }

  if (temp.length > length) {
    return temp.substring(0, length);
  }

  return temp;
};
