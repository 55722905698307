import { TopicThemes } from '@/enums';
import { ImpactTheme } from '@/helpers/theme';
import { ThemeProvider } from '@mui/material';
import { Default, Impact } from '@survey/start/themes';
import React from 'react';

interface IProps {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  theme?: TopicThemes;
}

const SurveyStart = ({ theme, ...props }: IProps) => {
  if (theme === TopicThemes.IMPACT) {
    return (
      <ThemeProvider theme={ImpactTheme}>
        <Impact {...props} />
      </ThemeProvider>
    );
  }

  return <Default {...props} />;
};

export default SurveyStart;
