import { CopyAnimation } from '@/animations';
import { Frame, Markdown, Progress, useTranslation } from '@/components';
import { LocalStorageKeys } from '@/enums';
import {
  addAlphaToHex,
  copyToClipboard,
  darkenHexColor,
  fontWeights
} from '@/helpers';
import { Mail01 } from '@/icons';
import ShareSvg from '@/images/share';
import ShareWithRelation from '@/images/share-with-relation';
import ThanksSvg from '@/images/thanks';
import { useAppContext } from '@contexts/app-context';
import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  List,
  ListItem,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import React, { createRef, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

interface LottieRefProps {
  play(): void;
  // eslint-disable-next-line no-unused-vars
  goToAndPlay(offset: number, x: boolean): void;
  // eslint-disable-next-line no-unused-vars
  goToAndStop(offset: number, x: boolean): void;
}

const LazyLottie = React.lazy(() => import('lottie-react'));

interface IProps {
  surveyData: SurveyStartResponse;
}

const SurveyShare = ({ surveyData }: IProps) => {
  const { showToast } = useAppContext();
  const theme = useTheme();
  const t = useTranslation('SurveyShare');

  const _copyAnimation = createRef<LottieRefProps>();

  const getName = () => localStorage.getItem(LocalStorageKeys.USER_NAME);

  const copy = async () => {
    playCopy();
    await copyToClipboard(
      t('HiThereIAmCompletingAPersonal', {
        link: surveyData!.url,
        name: getName()
      }).replace(/<[^>]+>/g, '')
    );
    showToast(t('LinkCopied'), 2e3, 'success');
  };

  const playCopy = () => {
    const anim = _copyAnimation.current;
    if (!anim) {
      return;
    }

    anim.goToAndPlay(0, true);
  };

  const initCopy = () => {
    const anim = _copyAnimation.current;
    if (!anim) {
      return;
    }

    anim.goToAndStop(120, true);
  };

  const relationRotation = (index: number) => {
    const relationsCount = surveyData!.relations.length;
    if (relationsCount < 1) {
      return '0';
    }

    switch (relationsCount) {
      case 2:
        return index === 0 ? '-18deg' : '18deg';

      case 3:
        return index === 1 ? '0deg' : index === 0 ? '-38deg' : '38deg';

      default:
        return index === 0
          ? '-53deg'
          : index === 1
          ? '-18deg'
          : index === 2
          ? '18deg'
          : '53deg';
    }
  };

  const renderSelfAsses = (theme: Theme) => (
    <>
      <Helmet>
        <meta name='theme-color' content='#0071E3' />
        <style>
          {`${theme.breakpoints.up('sm')} {
              body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.secondary.main,
                  10
                )}), url('/assets/images/bgOpenEnded.png');
                background-size: 50% 100%, 50% 100%;
                background-repeat: no-repeat, no-repeat;
                background-position: left center, right center;
              }
            }
            ${theme.breakpoints.down('sm')} {
              body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.secondary.main,
                  10
                )});
              }
            }`}
        </style>
      </Helmet>
      <Box
        width={{
          sm: '50%'
        }}
        height='100%'
        alignItems='center'
        justifyContent='center'
        display='flex'
      >
        <Box
          bgcolor='white'
          width={370}
          boxShadow={`0 0 12.2px 2.44px ${addAlphaToHex('#000', 10)}`}
          display='flex'
          flexDirection='column'
          borderRadius='29.27px'
          alignItems='center'
          pt={5}
          pb={6}
          px={4}
          textAlign='center'
        >
          <ThanksSvg />
          <Typography
            mt={2}
            fontWeight={fontWeights.semiBold}
            fontSize={20}
            color='#414042'
          >
            Thank you!
          </Typography>
          <Typography
            mt={16 / 12}
            fontWeight={fontWeights.regular}
            fontSize={16}
            color='#6D6E71'
          >
            We received your request. You will receive an email when the results
            are ready.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            component={Link}
            to='/'
            sx={{
              mt: 6
            }}
          >
            Back home
          </Button>
        </Box>
      </Box>
    </>
  );

  if (surveyData.selfAsses) {
    return renderSelfAsses(theme);
  }

  return (
    <>
      <Helmet>
        <style>
          {`body {
                background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                  theme.palette.primary.main,
                  15
                )});
              }`}
        </style>
      </Helmet>
      <Box
        borderRadius={{
          sm: '24px'
        }}
        boxShadow={{
          sm: '0 0 10px 2px rgba(0,0,0,0.1)'
        }}
        display='flex'
        flexDirection='column'
        bgcolor={{
          sm: 'white'
        }}
        mx={{
          sm: 'calc((100vw - 768px) / 2)',
          md: 'calc((100vw - 900px) / 2)',
          lg: 'calc((100vw - 1100px) / 2)'
        }}
        px={{
          sm: 2,
          md: 3,
          lg: 4
        }}
        py={{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5
        }}
      >
        <Box display='flex' flexDirection='column' mb={4}>
          {surveyData?.systemManageDisabled ? (
            <>
              <Typography fontSize={18} fontWeight={fontWeights.bold}>
                Well done!
              </Typography>
              <Typography fontSize={18} fontWeight={fontWeights.bold}>
                We will inform you when your report is ready.
              </Typography>
            </>
          ) : (
            <>
              <Hidden mdUp>
                <Typography
                  variant='subtitle1'
                  fontSize='18px'
                  fontWeight={fontWeights.extraBold}
                  sx={{
                    '&>ol, &>ul': {
                      fontWeight: fontWeights.regular
                    }
                  }}
                  mb={18 / 12}
                >
                  {surveyData &&
                    (surveyData.relations.length > 1
                      ? t('GreatStartPleaseShareWithRelations')
                      : t('GetFeedbackFromFriends', {
                          name:
                            surveyData.relations.length > 0
                              ? surveyData.relations[0].relation.toLowerCase()
                              : t('Friends'),
                          count:
                            surveyData.relations.length > 0
                              ? surveyData.relations[0].minResponses
                              : 3
                        }))}
                </Typography>
              </Hidden>
              <Box
                display='flex'
                flexDirection={{
                  xs: 'column',
                  sm: 'row'
                }}
                alignItems='center'
                mt={2}
              >
                {surveyData && surveyData.relations.length > 1 ? (
                  <Box
                    mb={{
                      xs: 32 / 12,
                      sm: undefined
                    }}
                    mr={{
                      xs: undefined,
                      sm: 32 / 12
                    }}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <ShareWithRelation />
                    <List sx={{ p: 0 }}>
                      {surveyData &&
                        surveyData.relations.map((x, i) => (
                          <ListItem
                            key={`relation_${x.id}`}
                            sx={{
                              whiteSpace: 'nowrap',
                              py: 16 / 12,
                              px: 8 / 12,
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                              backgroundColor: theme.palette.secondary.main,
                              borderRadius: '40px',
                              color: 'white',
                              position: 'relative',
                              '&:not(:last-of-type):not(:first-of-type)': {
                                my: 8 / 12
                              },
                              '&:last-of-type': {
                                mt: 8 / 12
                              },
                              '&:first-of-type': {
                                mb: 8 / 12
                              }
                            }}
                          >
                            <Box
                              borderTop='dashed 1px #BC3DF438'
                              position='absolute'
                              width='70px'
                              right='100%'
                              sx={{
                                transformOrigin: 'right',
                                transform: `rotate(${relationRotation(i)})`
                              }}
                            />
                            <Box
                              fontWeight={fontWeights.semiBold}
                              fontSize='10px'
                            >
                              {x.relation}
                            </Box>
                            <Box
                              fontWeight={fontWeights.light}
                              fontSize='10px'
                              ml={0.25}
                            >
                              {t('AtLeast', { min: x.minResponses })}
                            </Box>
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                ) : (
                  <ShareSvg />
                )}
                <Box
                  mr={{
                    xs: 0,
                    sm: 3
                  }}
                >
                  <Hidden mdDown>
                    <Typography
                      variant='subtitle1'
                      fontSize='18px'
                      fontWeight={fontWeights.extraBold}
                      sx={{
                        '&>ol, &>ul': {
                          fontWeight: fontWeights.regular
                        }
                      }}
                      mb={18 / 12}
                    >
                      {surveyData &&
                        (surveyData.relations.length > 1
                          ? t('GreatStartPleaseShareWithRelations')
                          : t('GetFeedbackFromFriends', {
                              name:
                                surveyData.relations.length > 0
                                  ? surveyData.relations[0].relation.toLowerCase()
                                  : t('Friends'),
                              count:
                                surveyData.relations.length > 0
                                  ? surveyData.relations[0].minResponses
                                  : 3
                            }))}
                    </Typography>
                  </Hidden>
                  <Frame
                    px={16 / 12}
                    pt={16 / 12}
                    pb={{
                      xs: 3,
                      sm: 4,
                      md: 3
                    }}
                    position='relative'
                    bgcolor='white'
                  >
                    <Markdown>
                      {surveyData &&
                        t('HiThereIAmCompletingAPersonal', {
                          link: `**${surveyData.url}**`,
                          name: getName()
                        }).replace(/\n/g, '<br/>')}
                    </Markdown>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={copy}
                      sx={{
                        fontSize: '16px',
                        position: 'absolute',
                        right: theme.spacing(16 / 12),
                        bottom: theme.spacing(16 / 12),
                        'svg>g': {
                          transform: 'scale(2.3)',
                          transformOrigin: 'center'
                        },
                        '&:hover .copy-bg': {
                          fill: darkenHexColor(theme.palette.primary.main, 10)
                        },
                        '& .copy-bg': {
                          fill: theme.palette.primary.main
                        }
                      }}
                    >
                      {t('$Copy')}
                      <Suspense fallback={<CircularProgress />}>
                        <LazyLottie
                          animationData={CopyAnimation}
                          style={{
                            marginLeft: 4,
                            height: '24px'
                          }}
                          autoplay={false}
                          loop={false}
                          lottieRef={_copyAnimation as any}
                          onDOMLoaded={initCopy}
                        />
                      </Suspense>
                    </Button>
                  </Frame>
                  <Typography
                    variant='subtitle1'
                    fontSize='12px'
                    mt={8 / 12}
                    pl={16 / 12}
                  >
                    {t('WeAlsoSentAConfirmation')}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
        {surveyData?.feedbackGroup &&
        surveyData.feedbackGroup.items.length > 0 ? (
          <Box
            bgcolor={addAlphaToHex(theme.palette.primary.main, 3)}
            borderRadius='24px'
            px={32 / 12}
            py={32 / 12}
          >
            <Typography
              color={theme.palette.secondary.main}
              fontSize={16}
              fontWeight={fontWeights.bold}
            >
              <Mail01
                style={{
                  marginBottom: -6,
                  marginRight: 4,
                  marginLeft: 4,
                  color: theme.palette.primary.main
                }}
              />
              <Hidden smUp>
                <br />
              </Hidden>
              {t('WeInvitedFollowingPeople')}
            </Typography>
            <Box>
              {surveyData.feedbackGroup.items.map((x, i) => (
                <Box
                  key={`email${i}`}
                  component='span'
                  display={{
                    xs: 'block',
                    sm: 'inline-block'
                  }}
                  fontSize={{
                    xs: 12,
                    sm: 14
                  }}
                  fontWeight={fontWeights.light}
                  bgcolor='white'
                  borderRadius='24px'
                  lineHeight={{
                    xs: '32px',
                    sm: '42px'
                  }}
                  px={{
                    xs: 0,
                    sm: 16 / 12
                  }}
                  whiteSpace='nowrap'
                  boxShadow={`0 2px 4px ${addAlphaToHex('#000', 25)}`}
                  mr={{
                    xs: 0,
                    sm: 16 / 12
                  }}
                  mt={{
                    xs: 8 / 12,
                    sm: 16 / 12
                  }}
                  textAlign='center'
                >
                  {x.email}
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Progress progress={20} />
        )}
      </Box>
    </>
  );
};

export default SurveyShare;
