import { getLanguage, setLanguage, useTranslation } from '@/components';
import { AvailableLanguages, fontWeights } from '@/helpers';
import { Globe04 } from '@/icons';
import Logo from '@/icons/logo';
import { useAppContext } from '@contexts/app-context';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Header = () => {
  const { header } = useAppContext();
  const t = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorElementState, setAnchorElementState] = useState<Element | null>(
    null
  );
  const [openLanguagesListState, setOpenLanguagesListState] = useState(false);

  const openLanguagesList = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElementState(e.currentTarget);
    setOpenLanguagesListState(true);
  };

  const onBack = () => navigate(header.back!);

  return (
    <Box
      component='header'
      color={theme.palette.grey[800]}
      px={{
        xs: 3,
        sm: 4,
        md: 5
      }}
      pt={{
        xs: 1,
        sm: 2,
        md: 3
      }}
      mb={{
        xs: 1,
        sm: 2,
        md: 3
      }}
      height='48px'
      display='flex'
      alignItems='center'
    >
      <Menu
        anchorEl={anchorElementState}
        open={openLanguagesListState}
        onClose={() => setOpenLanguagesListState(false)}
        onClick={() => setOpenLanguagesListState(false)}
        slotProps={{
          paper: {
            onMouseLeave: openLanguagesList,
            elevation: 0,
            sx: {
              borderRadius: '24px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              py: 1,
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              }
            }
          }
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {AvailableLanguages.map((x) => (
          <MenuItem
            key={x.code}
            onClick={async () => await setLanguage(x.code)}
            sx={{
              bgcolor: getLanguage() === x.code ? '#F9ECFF' : undefined,
              px: 32 / 12,
              minHeight: 'auto'
            }}
          >
            <Box fontFamily='monospace' component='strong' mr={0.5}>
              {x.code.toUpperCase()}
              {' |'}
            </Box>
            {x.name}
          </MenuItem>
        ))}
      </Menu>
      {(header.back && typeof header.back === 'string' && (
        <Tooltip title='Back'>
          <IconButton onClick={onBack}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
      )) ||
        header.back}
      <Logo />
      <Link
        component={RouterLink}
        lineHeight='12px'
        color='inherit'
        className='logo'
        fontWeight={fontWeights.bold}
        fontSize='24px'
        ml={0.5}
        sx={{
          textDecoration: 'none'
        }}
        to={header.linkTo}
      >
        100 Facets
      </Link>
      <List sx={{ ml: 'auto', listStyle: 'none', p: 0 }}>
        <ListItem
          sx={{
            display: 'inline-block',
            width: 'auto',
            p: 0
          }}
        >
          <Tooltip title={t('Language')}>
            <IconButton
              key={'languagesButton'}
              onMouseEnter={openLanguagesList}
              onClick={openLanguagesList}
              aria-controls={
                openLanguagesListState ? 'languages-list' : undefined
              }
              aria-haspopup='true'
              aria-expanded={openLanguagesListState ? 'true' : undefined}
              sx={{
                bgcolor: openLanguagesListState ? '#F9ECFF' : undefined,
                borderRadius: '10px',
                color: openLanguagesListState ? '#414042' : 'inherit'
              }}
            >
              <Globe04 />
              <Box
                fontSize={{
                  xs: '16px',
                  sm: '20px',
                  md: '24px'
                }}
                fontWeight={fontWeights.bold}
                fontFamily='monospace'
              >
                {getLanguage().toUpperCase()}
              </Box>
            </IconButton>
          </Tooltip>
        </ListItem>
        {header.tools?.map((x, i) => (
          <ListItem
            sx={{
              display: 'inline-block',
              width: 'auto',
              p: 0
            }}
            key={i}
          >
            {x}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Header;
