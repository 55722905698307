import { LocalStorageKeys, TopicThemes } from '@/enums';
import { ImpactTheme } from '@/helpers/theme';
import { ThemeProvider } from '@mui/material';
import { Default, Impact } from '@survey/thanks/themes';
import React from 'react';

const SurveyThanks = () => {
  const thanksData = localStorage.getItem(LocalStorageKeys.SURVEY_THANKS_DATA);
  const surveyThanksData =
    (thanksData &&
      (JSON.parse(thanksData) as {
        topic: string;
        customizations?: CompanyCustomizations;
        theme?: TopicThemes;
      })) ||
    undefined;

  if (surveyThanksData?.theme === TopicThemes.IMPACT) {
    return <ThemeProvider theme={ImpactTheme}>{<Impact />}</ThemeProvider>;
  }

  return <Default />;
};

export default SurveyThanks;
