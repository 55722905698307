import '@/extensions/string.extensions';
import {
  addAlphaToHex,
  darkenHexColor,
  fontWeights,
  getTextColor,
  lightenHexColor
} from '@/helpers';
import { ThemeOptions, createTheme } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#BC3DF4',
      light: lightenHexColor('#BC3DF4', 72),
      dark: darkenHexColor('#BC3DF4', 72),
      contrastText: getTextColor('#BC3DF4')
    },
    secondary: {
      main: '#BC3DF4',
      light: lightenHexColor('#BC3DF4', 72),
      dark: darkenHexColor('#BC3DF4', 72),
      contrastText: getTextColor('#BC3DF4')
    },
    success: {
      main: '#32EFBE',
      light: lightenHexColor('#32EFBE', 72),
      dark: darkenHexColor('#32EFBE', 72),
      contrastText: getTextColor('#32EFBE')
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 960,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeightBold: fontWeights.bold,
    fontWeightLight: fontWeights.light,
    fontWeightMedium: fontWeights.medium,
    fontWeightRegular: fontWeights.regular,
    htmlFontSize: 12,
    h1: {
      fontSize: 32,
      fontWeight: fontWeights.semiBold
    },
    h2: {
      fontSize: 24,
      fontWeight: fontWeights.semiBold
    },
    h3: {
      fontSize: 20,
      fontWeight: fontWeights.medium
    },
    h4: {
      fontSize: 16,
      fontWeight: fontWeights.medium
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: fontWeights.regular,
      lineHeight: '22px'
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: fontWeights.regular,
      lineHeight: '20px'
    },
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 14
    }
  },
  spacing: 12,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '24px !important',
          backgroundColor: 'white',
          padding: '0 !important'
        },
        input: {
          lineHeight: '24px',
          fontSize: '12px',
          padding: '12px 16px !important',
          '@media (min-width: 760px)': {
            fontSize: '14px'
          },
          '::-ms-reveal,::-ms-clear': {
            display: 'none'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#414042',
          marginBottom: '8px',
          fontWeight: fontWeights.medium,
          '@media (min-width: 760px)': {
            fontSize: '16px'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: 'visible'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderBottom: 'solid 1px #DDD'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          textTransform: 'none',
          fontSize: '18px',
          fontWeight: fontWeights.semiBold,
          '.admin &': {
            fontSize: '14px'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: 'solid 1px #CCC',
          borderRadius: '10px',
          '&.stickyFooter tfoot td': {
            '&:first-of-type': {
              borderBottomLeftRadius: '10px'
            },
            '&:last-of-type': {
              borderBottomRightRadius: '10px'
            },
            backgroundColor: 'white',
            position: 'sticky',
            bottom: 2
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: fontWeights.medium,
          fontSize: '14px',
          textAlign: 'center',
          '&:first-of-type': {
            borderTopLeftRadius: '10px'
          },
          '&:last-of-type': {
            borderTopRightRadius: '10px'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          position: 'relative',
          boxSizing: 'border-box'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #DDD'
        },
        title: {
          fontSize: '16px',
          fontWeight: fontWeights.medium
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    }
  }
};
const Theme = createTheme(themeOptions);

export const DarkTheme = createTheme({
  ...themeOptions,
  palette: {
    mode: 'dark'
  }
});

export const ImpactTheme = createTheme({
  ...themeOptions,
  palette: {
    primary: {
      main: '#0071E3',
      light: lightenHexColor('#0071E3', 72),
      dark: darkenHexColor('#0071E3', 72),
      contrastText: getTextColor('#0071E3')
    },
    secondary: {
      main: '#0071E3',
      light: lightenHexColor('#0071E3', 72),
      dark: darkenHexColor('#0071E3', 72),
      contrastText: getTextColor('#0071E3')
    }
  },
  components: {
    ...themeOptions.components,
    MuiDialog: {
      ...themeOptions.components?.MuiDialog,
      styleOverrides: {
        ...themeOptions.components?.MuiDialog?.styleOverrides,
        paper: {
          ...(typeof themeOptions.components?.MuiDialog?.styleOverrides
            ?.paper === 'object'
            ? themeOptions.components?.MuiDialog?.styleOverrides?.paper
            : {}),
            borderRadius: '24px'
        }
      }
    },
    MuiInputLabel: {
      ...themeOptions.components?.MuiInputLabel,
      styleOverrides: {
        ...themeOptions.components?.MuiInputLabel?.styleOverrides,
        root: {
          ...(typeof themeOptions.components?.MuiInputLabel?.styleOverrides
            ?.root === 'object'
            ? themeOptions.components?.MuiInputLabel?.styleOverrides?.root
            : {}),
          color: 'white',
          fontWeight: fontWeights.regular,
          fontSize: 14
        }
      }
    },
    MuiOutlinedInput: {
      ...themeOptions.components?.MuiOutlinedInput,
      styleOverrides: {
        ...themeOptions.components?.MuiOutlinedInput?.styleOverrides,
        root: {
          ...(typeof themeOptions.components?.MuiOutlinedInput?.styleOverrides
            ?.root === 'object'
            ? themeOptions.components?.MuiOutlinedInput?.styleOverrides?.root
            : {}),
          backgroundColor: addAlphaToHex('#D9D9D9', 38),
          border: 'solid white 1px',
          color: '#FFFDFD'
        },
        notchedOutline: {
          ...(typeof themeOptions.components?.MuiOutlinedInput?.styleOverrides
            ?.notchedOutline === 'object'
            ? themeOptions.components?.MuiOutlinedInput?.styleOverrides
                ?.notchedOutline
            : {}),
          border: 'none'
        },
        error: {
          ...(typeof themeOptions.components?.MuiOutlinedInput?.styleOverrides
            ?.error === 'object'
            ? themeOptions.components?.MuiOutlinedInput?.styleOverrides?.error
            : {}),
          border: 'solid #EC4849 1px'
        }
      }
    },
    MuiRadio: {
      ...themeOptions.components?.MuiRadio,
      styleOverrides: {
        ...themeOptions.components?.MuiRadio?.styleOverrides,
        root: {
          ...(typeof themeOptions.components?.MuiRadio?.styleOverrides?.root ===
          'object'
            ? themeOptions.components?.MuiRadio?.styleOverrides?.root
            : {}),
          color: '#F5F5F5'
        }
      }
    },
    MuiButton: {
      ...themeOptions.components?.MuiButton,
      styleOverrides: {
        ...themeOptions.components?.MuiButton?.styleOverrides,
        root: {
          ...(typeof themeOptions.components?.MuiButton?.styleOverrides
            ?.root === 'object'
            ? themeOptions.components?.MuiButton?.styleOverrides?.root
            : {}),
          fontFamily: 'Inter'
        }
      }
    }
  }
});

export const CanvasTheme = createTheme({
  palette: {
    primary: {
      main: '#BC3DF4'
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '12px !important',
          backgroundColor: 'white',
          padding: '0 !important'
        },
        input: {
          lineHeight: '18px',
          fontSize: '12px',
          padding: '12px 16px !important'
        }
      }
    }
  }
});

export default Theme;
