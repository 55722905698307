import { Markdown, useTranslation } from '@/components';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import { RectifyType } from '@/enums';
import { addAlphaToHex, fontWeights, ignore, webClient } from '@/helpers';
import WriterSvg from '@/images/writer';
import { Send } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const DiagnoseStart = () => {
  const mainTheme = useTheme();
  const t = useTranslation('Diagnose');
  const navigate = useNavigate();
  const reference = useParams<'surveyId'>().surveyId!;

  const [issue, setIssue] = useState('');
  const [summary, setSummary] = useState('');
  const [customizations, setCustomizations] = useState<CompanyCustomizations>();
  const [challenges, setChallenges] = useState<PredefinedChallengeResponse[]>();
  const [submitOnEffect, setSubmitOnEffect] = useState(false);
  const [noChallenge, setNoChallenge] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [chatHistory, setChatHistory] = useState<RectifyTextHistoryBody[]>([]);
  const [showMakeBetter, setShowMakeBetter] = useState(false);
  const [lastHistory, setLastHistory] = useState<RectifyTextHistoryBody>();

  const minLength = 10;

  const submit = async () => {
    try {
      if (summary !== '' || canSubmit) {
        const res = await webClient.post<SingleResponse<{ id: string }>>(
          '/diagnoses/',
          {
            survey: reference,
            issue: summary || issue
          }
        );

        return navigate(`/diagnose/${res.data.data.id}/`, {
          replace: true
        });
      }

      const res = await webClient.api.tools.rectifier({
        input: issue,
        type: RectifyType.DIAGNOSE_ISSUE,
        reference,
        history: chatHistory.map((x) => ({
          role: x.role,
          content: x.content
        }))
      });

      const resData = res.data.data;
      setChatHistory((x) => [
        ...x,
        {
          content: issue,
          role: 'user'
        },
        {
          content: resData.content,
          role: 'assistant',
          canSubmit: resData.canSubmit
        }
      ]);

      if (resData.canSubmit) {
        setLastHistory({
          content: resData.content,
          role: 'assistant'
        });
      } else {
        setLastHistory({
          content: issue,
          role: 'user'
        });
      }

      setCanSubmit(resData.canSubmit);
      setSummary(resData.content);
    } catch (exp) {
      console.log(exp);
    }
  };

  useEffect(() => {
    if (submitOnEffect && summary !== '') {
      submit();
    }
  }, [submitOnEffect, summary]);

  useEffect(() => {
    webClient.api.diagnoses
      .survey(reference)
      .then((x) => {
        setCustomizations(x.data.data);

        webClient.api.surveys
          .getChallenges(reference)
          .then((x) => {
            setChallenges(x.data.data);
          })
          .catch(ignore);
      })
      .catch(ignore);
  }, []);

  const render = (theme: Theme) => {
    if (!noChallenge && challenges && challenges.length > 0) {
      return (
        <>
          <Typography
            variant='h2'
            fontSize={27}
            fontWeight={fontWeights.semiBold}
          >
            {t('ShareYourChallengesAndGet')}
          </Typography>
          <Box textAlign='left' mt={3}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setNoChallenge(true)}
            >
              {t('StartFromScratch')}
            </Button>
          </Box>
          <Typography fontSize={27} fontWeight={fontWeights.regular} mt={4}>
            {t('ExploreHotChallenges')}
          </Typography>
          <Grid container spacing={32 / 12} mt={2} px={2} pb={1}>
            {challenges.map((x) => (
              <Grid
                item
                key={x.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                onClick={() => {
                  setSubmitOnEffect(true);
                  setSummary(x.challenge);
                }}
                bgcolor='white'
                border={`solid 1px ${theme.palette.secondary.main}`}
                borderRadius='24px'
                p={2}
                fontWeight={fontWeights.extraLight}
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    boxShadow: `0 4px 4px ${addAlphaToHex('#000', 25)}`,
                    cursor: 'pointer'
                  }
                }}
              >
                <Box
                  fontSize={16}
                  fontWeight={fontWeights.semiBold}
                  color={theme.palette.primary.main}
                  mb={8 / 12}
                >
                  {x.title}
                </Box>
                {x.challenge?.substring(0, 200)}
                {x.challenge?.length > 200 && '...'}
              </Grid>
            ))}
          </Grid>
        </>
      );
    }

    if (summary !== '') {
      return (
        <>
          <Dialog
            open={showMakeBetter}
            onClose={() => setShowMakeBetter(false)}
          >
            <DialogTitle>{t('HowToContinue')}</DialogTitle>
            <DialogContent>
              <Button
                fullWidth
                onClick={() => {
                  setShowMakeBetter(false);
                  setSummary('');
                  setIssue(lastHistory?.content || '');
                }}
              >
                {t('ImproveCurrentVersion')}
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setShowMakeBetter(false);
                  setChatHistory([]);
                  setLastHistory(undefined);
                  setIssue('');
                  setSummary('');
                }}
              >
                {t('MakeNewVersionFromScratch')}
              </Button>
            </DialogContent>
          </Dialog>
          <Box
            borderRadius={{
              xs: `calc((100% - ${theme.spacing(
                2
              )}) / 2) calc((100% - ${theme.spacing(2)}) / 2) 24px 24px`,
              sm: '300px 300px 24px 24px'
            }}
            boxShadow={`0 0 10px 2px ${addAlphaToHex('#000', 10)}`}
            mx={{
              xs: 1,
              sm: 'calc((100% - 600px) / 2)'
            }}
            display='flex'
            flexDirection='column'
            py={6}
            px={32 / 12}
          >
            <WriterSvg />
            <Typography
              variant='body1'
              fontSize={20}
              fontWeight={fontWeights.semiBold}
              color='#414042'
              mt={5}
            >
              {t('ThisIsWhatWeHeardFromYou')}
            </Typography>
            <Typography
              variant='body1'
              fontSize={18}
              fontWeight={fontWeights.extraLight}
              fontStyle='italic'
              color='#414042'
              mt={32 / 12}
            >
              <Markdown>
                {canSubmit ? summary.clear() : t('IssueIsNotRelated')}
              </Markdown>
            </Typography>
            <Box mt={2} alignSelf='center'>
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={(canSubmit && submit) || undefined}
                disabled={!canSubmit}
              >
                {t('$Submit')}
              </Button>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                onClick={() => setShowMakeBetter(true)}
                sx={{
                  ml: 16 / 12
                }}
              >
                {t('$MakeItBetter')}
              </Button>
            </Box>
          </Box>
        </>
      );
    }

    return (
      <Box
        mx={{
          xs: 3,
          sm:
            lastHistory && lastHistory.role === 'user'
              ? 3
              : 'calc((100vw - 650px) / 2)'
        }}
      >
        {challenges && challenges.length > 0 && (
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => setNoChallenge(false)}
          >
            {t('$Back')}
          </Button>
        )}
        <Typography
          variant='body1'
          fontSize={27}
          fontWeight={fontWeights.bold}
          color='#414042'
        >
          {canSubmit
            ? t('ThisIsSummaryOfYourLastInput')
            : t('WriteAboutYourRealChallengesHere')}
        </Typography>
        <Box
          display='flex'
          flexDirection={{
            xs: 'column',
            sm: 'row'
          }}
          alignItems={{
            xs: 'stretch',
            sm: 'flex-end'
          }}
        >
          {lastHistory && lastHistory.role === 'user' && (
            <Box
              order={{
                xs: '0',
                sm: '1'
              }}
              flex='auto 1 1'
              borderRadius='24px'
              bgcolor={addAlphaToHex('#fff', 80)}
              p={32 / 12}
              ml={{
                xs: 0,
                sm: 1,
                md: 2,
                lg: 4,
                xl: 6
              }}
              mt={{
                xs: 1,
                sm: 0
              }}
              display='flex'
              flexDirection='column'
            >
              <Typography
                fontWeight={fontWeights.medium}
                fontSize={18}
                mb={16 / 12}
              >
                {t('ThisIsSummaryOfYourLastInput')}
              </Typography>
              <Box
                fontWeight={fontWeights.extraLight}
                fontSize={16}
                fontStyle='italic'
              >
                <Markdown>{lastHistory.content}</Markdown>
              </Box>
            </Box>
          )}
          <Box
            flex='auto 1 1'
            order={{
              xs: '1',
              sm: '0'
            }}
            mt={{
              xs: 1.5,
              sm: 3
            }}
            bgcolor='white'
            border={`solid 1px ${addAlphaToHex(
              theme.palette.secondary.main,
              50
            )}`}
            borderRadius='24px'
            p={{
              xs: 16 / 12,
              sm: 32 / 12
            }}
            display='flex'
            flexDirection='column'
            position='relative'
          >
            <Box
              position='absolute'
              top='16px'
              right='16px'
              zIndex={1}
              color={theme.palette.primary.main}
            >
              <Box
                component='span'
                color={issue.trim().length < minLength ? '#EC4849' : '#32EFBE'}
              >
                {issue.trim().length}
              </Box>
              <Box component='span'>/{minLength}</Box>
            </Box>
            <TextField
              type='text'
              name='comment'
              multiline
              rows={3}
              value={issue}
              fullWidth
              placeholder={t('WriteAtLeastMinCharacters', {
                min: minLength
              })}
              onChange={(e) => setIssue(e.target.value)}
              sx={{
                '& fieldset': {
                  border: 'none',
                  outline: 'none'
                }
              }}
            />
            <Tooltip title={t('$Submit')}>
              <IconButton
                sx={{
                  mt: 1,
                  alignSelf: 'flex-end'
                }}
                type='button'
                onClick={submit}
                disabled={issue.trim().length < minLength}
              >
                <Send fontSize='large' />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  if (
    customizations &&
    (customizations.primaryColor || customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider
        customizations={customizations}
        children={render}
      />
    );
  }

  return render(mainTheme);
};

export default DiagnoseStart;
