import { Alert, Loading, useTranslation } from '@/components';
import { CodeTypes } from '@/enums';
import { Logger, webClient } from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import SurveyFeedback from '@survey/feedback';
import SurveyIndex from '@survey/index';
import SurveyReportJohari from '@survey/report/johari';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

interface ICodeResponse {
  type: CodeTypes;
  metadata: Record<string, any>;
}

interface IState {
  type: CodeTypes | -1;
  metadata: Record<string, any>;
}

const CodeRouter = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IState>();
  const code = useParams<'code'>().code!;
  const navigate = useNavigate();
  const t = useTranslation();
  const { showToast } = useAppContext();

  localStorage.clear();

  useEffect(() => {
    function checkCode() {
      setLoading(true);
      webClient
        .get<SingleResponse<ICodeResponse>>(`/short-codes/${code}/`)
        .then((res) => {
          setData(res.data.data);
          Logger.log(res.data.data);
          setLoading(false);
        })
        .catch(() => {
          setData({
            type: CodeTypes.Invalid,
            metadata: {}
          });
          setLoading(false);
        });
    }

    if (!data && !loading) {
      checkCode();
    }
  }, [data, loading, code]);

  if (!data) {
    return <Loading />;
  }

  switch (data.type) {
    case CodeTypes.Feedback:
      return <SurveyFeedback code={code} name={data.metadata.name} />;

    case CodeTypes.Report:
      return <SurveyReportJohari />;

    case CodeTypes.Project:
      return <SurveyIndex project={code} />;

    case CodeTypes.VerifyEmail:
      navigate('/verify');
      return null;

    case CodeTypes.Invalid:
      showToast(t('CodeIsNotValid'), 3e3, 'error');
      navigate('/', { replace: true });
      return null;

    case CodeTypes.GainAccess:
      return (
        <Alert
          text={t('OrganizerGainAccess')}
          closable
          onClose={() => navigate('/')}
        />
      );

    case CodeTypes.CancelAccess:
      return (
        <Alert
          text={t('OrganizerCancelAccess')}
          closable
          onClose={() => navigate('/')}
        />
      );

    default:
      return null;
  }
};

export default CodeRouter;
