import { useAppContext } from '@/contexts/app-context';
import { LocalStorageKeys, TopicThemes } from '@/enums';
import { ImpactTheme } from '@/helpers/theme';
import { ThemeProvider } from '@mui/material';
import { Default, Impact } from '@survey/share/themes';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const SurveyShare = () => {
  const { setMainClasses } = useAppContext();
  const navigate = useNavigate();

  const [surveyData, setSurveyData] = useState<SurveyStartResponse>();

  useEffect(() => {
    setMainClasses('main-flex');

    const data = localStorage.getItem(LocalStorageKeys.SURVEY_DATA);
    if (!data) {
      setTimeout(() =>
        navigate('/', {
          replace: true
        })
      );
      return;
    }

    setSurveyData(JSON.parse(data) as SurveyStartResponse);
  }, []);

  if (!surveyData) {
    return <></>;
  }

  if (surveyData.theme === TopicThemes.IMPACT) {
    return (
      <ThemeProvider theme={ImpactTheme}>
        {<Impact surveyData={surveyData} />}
      </ThemeProvider>
    );
  }

  return <Default surveyData={surveyData} />;
};

export default SurveyShare;
