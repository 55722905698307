import { Frame, useTranslation } from '@/components';
import { LocalStorageKeys } from '@/enums';
import { fontWeights } from '@/helpers';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import React, { useState } from 'react';

interface IProps {
  nickName: string;
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  skipRelations: boolean;
  skipFamiliarity: boolean;
  next: Action;
}

const SurveyRelationship = ({
  nickName,
  relations,
  familiarity,
  skipRelations,
  skipFamiliarity,
  next
}: IProps) => {
  const t = useTranslation('Relations');

  const [relation, setRelation] = useState('');
  const [familiarityId, setFamiliarityId] = useState('');

  const submit = async () => {
    if (relations && relations.length > 1 && !skipRelations) {
      localStorage.setItem(LocalStorageKeys.FEEDBACK_RELATION, relation);
    }

    if (familiarity && !skipFamiliarity) {
      localStorage.setItem(
        LocalStorageKeys.FEEDBACK_FAMILIARITY,
        familiarityId
      );
    }
    next();
  };

  return (
    <Frame
      display='flex'
      flexDirection='column'
      bgcolor={{
        xs: 'none',
        sm: 'white'
      }}
      px={{
        xs: 0,
        sm: 3
      }}
      py={3}
      my={{
        xs: 0,
        sm: 2
      }}
      mx={{
        xs: 1,
        sm: 'calc((100vw - 500px) / 2)'
      }}
    >
      {relations && relations.length > 1 && !skipRelations && (
        <>
          <Typography
            variant='h3'
            sx={{
              fontWeight: fontWeights.semiBold,
              color: '#4F4F4F'
            }}
          >
            {t('WhatIsYourRelation', { name: nickName })}
          </Typography>
          <Typography
            variant='h4'
            sx={{
              fontWeight: fontWeights.semiBold,
              color: '#4F4F4F'
            }}
          >
            ({t('YouAre', { name: nickName })})
          </Typography>
          <RadioGroup
            value={relation}
            onChange={(e) => setRelation(e.target.value)}
            sx={{ mt: 2 }}
          >
            {relations.map((x, i) => (
              <FormControlLabel
                key={`relations${i}`}
                value={x.id}
                control={<Radio />}
                label={x.relation}
              />
            ))}
          </RadioGroup>
        </>
      )}
      {familiarity && !skipFamiliarity && (
        <>
          <Typography
            variant='h3'
            sx={{
              fontWeight: fontWeights.semiBold,
              color: '#4F4F4F',
              mt: relations && relations.length > 1 ? 2 : undefined
            }}
          >
            {familiarity.title.replace('{name}', nickName)}
          </Typography>
          <RadioGroup
            value={familiarityId}
            onChange={(e) => setFamiliarityId(e.target.value)}
            sx={{ mt: 2 }}
          >
            {familiarity.options.map((x, i) => (
              <FormControlLabel
                key={`familiarity${i}`}
                value={x.id}
                control={<Radio />}
                label={x.title}
              />
            ))}
          </RadioGroup>
        </>
      )}
      <Button
        sx={{
          mt: 1
        }}
        type='button'
        onClick={submit}
        disabled={
          (relations &&
            relations.length > 1 &&
            relation === '' &&
            !skipRelations) ||
          (familiarity &&
            !familiarity.optional &&
            familiarityId === '' &&
            !skipFamiliarity)
        }
        variant='contained'
      >
        {t('$Next')}
      </Button>
    </Frame>
  );
};

export default SurveyRelationship;
