import CustomizedThemeProvider from '@/components/customized-theme-provider';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import { LocalStorageKeys, TopicThemes } from '@/enums';
import { webClient } from '@/helpers';
import ProjectDetails from '@survey/project';
import SurveyStart from '@survey/start';
import { useEffect, useState } from 'react';

enum Steps {
  // eslint-disable-next-line no-unused-vars
  Intro = 0,
  // eslint-disable-next-line no-unused-vars
  Start = 1
}

interface IProps {
  project?: string;
}

const SurveyIndex = ({ project }: IProps) => {
  const [step, setStep] = useState(Steps.Intro);
  const [topic, setTopic] = useState<
    SurveyToResponseResponse | TopicSurveyResponse | undefined
  >();
  const [theme, setTheme] = useState<TopicThemes>(TopicThemes.DEFAULT);

  const getCurrentTopic = () =>
    localStorage.getItem(LocalStorageKeys.SURVEY_TOPIC) || undefined;

  const loadTopic = async () => {
    try {
      const res = await webClient.api.general.topicForSurvey(
        false,
        undefined,
        project,
        getCurrentTopic()
      );

      setTheme(res.data.data.theme || TopicThemes.DEFAULT);
      setTopic(res.data.data);
    } catch (exp) {
      alert(exp);
    }
  };

  useEffect(() => {
    const languageSubscriptionId = languageChangeSubscribe(loadTopic);

    loadTopic();

    return () => {
      languageChangeUnsubscribe(languageSubscriptionId);
    };
  }, []);

  if (!topic) {
    return <></>;
  }

  const render = () => {
    if (!project) {
      return <SurveyStart topic={topic} theme={theme} />;
    }

    if (topic.projectDescription && step === Steps.Intro) {
      return (
        <ProjectDetails
          customizations={topic.customizations}
          onsubmit={() => setStep(Steps.Start)}
          projectDescription={topic.projectDescription}
          projectName={topic.projectName}
          theme={theme}
        />
      );
    }

    return <SurveyStart topic={topic} project={project} theme={theme} />;
  };

  if (
    topic.customizations &&
    (topic.customizations.primaryColor || topic.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={topic.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default SurveyIndex;
