import { Markdown } from '@/components';
import { addAlphaToHex, fontFamilies, fontWeights } from '@/helpers';
import { Box, Button, Hidden, Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';

interface IProps {
  projectName?: string;
  projectDescription: string;
  customizations?: CompanyCustomizations;
  onsubmit(): void;
}

const ProjectDetails = ({
  projectName,
  projectDescription,
  customizations,
  onsubmit
}: IProps) => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <meta name='theme-color' content='#0071E3' />
        <style>
          {`body {
              background-image: linear-gradient(to bottom left, transparent, ${addAlphaToHex(
                theme.palette.primary.main,
                15
              )});
            }`}
        </style>
      </Helmet>
      <Box
        display='flex'
        flexDirection={{
          xs: 'column',
          sm: 'row'
        }}
        boxShadow='0 0 10px 2px rgba(0, 0, 0, 0.1)'
        bgcolor='white'
        my={{
          xs: 0,
          sm: 2
        }}
        mx={{
          xs: 1,
          md: 'calc((100vw - 960px) / 2)'
        }}
        borderRadius='24px'
      >
        <Box
          display='flex'
          px={{
            xs: 16 / 12,
            sm: 4
          }}
          py={{
            xs: 16 / 12,
            sm: 4
          }}
          flex='auto 1 1'
          flexDirection='column'
          order={{
            xs: 2,
            sm: 1
          }}
        >
          <Hidden smDown>
            {customizations?.logo && (
              <Box
                component='img'
                src={customizations?.logo}
                maxWidth='100px'
                mb={2}
              />
            )}
            <Typography
              variant='h3'
              fontSize={32}
              fontWeight={fontWeights.regular}
              fontFamily={fontFamilies.LibreBaskerville}
            >
              {projectName}
            </Typography>
          </Hidden>
          <Box
            mt={{
              sm: 2
            }}
            fontSize={14}
            fontWeight={fontWeights.light}
          >
            <Markdown>{projectDescription}</Markdown>
          </Box>
          <Box textAlign='left'>
            <Button
              variant='contained'
              onClick={onsubmit}
              sx={{ mt: 2, px: 2 }}
            >
              Start
            </Button>
          </Box>
        </Box>
        <Box
          order={{
            xs: 1,
            sm: 2
          }}
          flex={{
            xs: `calc((100vw - 64px) * 226 / 328) 0 0`,
            sm: '50% 0 0'
          }}
          sx={{
            backgroundImage: 'url(/assets/images/bgOpenEnded.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top'
          }}
          borderRadius='24px'
          height={{
            sm: '100%'
          }}
          position='relative'
        >
          <Hidden smUp>
            <Typography
              variant='h3'
              fontSize={20}
              fontWeight={fontWeights.bold}
              position='absolute'
              bottom={0}
              left={0}
              right={0}
              pb={2}
              px={2}
              color='#fff'
              borderRadius='0 0 24px 24px'
              fontFamily={fontFamilies.LibreBaskerville}
              sx={{
                background: 'linear-gradient(to bottom, transparent, #0008)'
              }}
            >
              {projectName}
            </Typography>
          </Hidden>
        </Box>
      </Box>
    </>
  );
};

export default ProjectDetails;
