import { Frame, useTranslation } from '@/components';
import { LocalStorageKeys } from '@/enums';
import { addAlphaToHex, fontWeights } from '@/helpers';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  nickName: string;
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  skipRelations: boolean;
  skipFamiliarity: boolean;
  next: Action;
}

const SurveyRelationship = ({
  nickName,
  relations,
  familiarity,
  skipRelations,
  skipFamiliarity,
  next
}: IProps) => {
  const t = useTranslation('Relations');

  const [relation, setRelation] = useState('');
  const [familiarityId, setFamiliarityId] = useState('');

  const submit = async () => {
    if (relations && relations.length > 1 && !skipRelations) {
      localStorage.setItem(LocalStorageKeys.FEEDBACK_RELATION, relation);
    }

    if (familiarity && !skipFamiliarity) {
      localStorage.setItem(
        LocalStorageKeys.FEEDBACK_FAMILIARITY,
        familiarityId
      );
    }
    next();
  };

  return (
    <>
      <Helmet>
        <meta name='theme-color' content='#0071E3' />
        <style>
          {`body {
              background: url('/assets/images/bgOpenEnded.png');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center top;
              backdrop-filter: brightness(40%);
            }
            #root > div > header {
              color: white !important;
            }`}
        </style>
      </Helmet>
      <Frame
        display='flex'
        flexDirection='column'
        color='white'
        bgcolor={{
          xs: 'none',
          sm: addAlphaToHex('#fff', 20)
        }}
        px={{
          xs: 0,
          sm: 3
        }}
        py={3}
        my={{
          xs: 0,
          sm: 2
        }}
        mx={{
          xs: 1,
          sm: 'calc((100vw - 500px) / 2)'
        }}
      >
        {relations && relations.length > 1 && !skipRelations && (
          <>
            <Typography
              variant='h3'
              fontSize={16}
              fontWeight={fontWeights.bold}
            >
              {t('WhatIsYourRelation', { name: nickName })}
            </Typography>
            <Typography
              variant='h4'
              fontSize={12}
              fontWeight={fontWeights.semiBold}
            >
              ({t('YouAre', { name: nickName })})
            </Typography>
            <RadioGroup
              value={relation}
              onChange={(e) => setRelation(e.target.value)}
              sx={{ mt: 1 }}
            >
              {relations.map((x, i) => (
                <FormControlLabel
                  key={`relations${i}`}
                  value={x.id}
                  control={<Radio />}
                  label={x.relation}
                />
              ))}
            </RadioGroup>
          </>
        )}
        {familiarity && !skipFamiliarity && (
          <>
            <Typography
              variant='h3'
              fontSize={16}
              fontWeight={fontWeights.bold}
              mt={relations && relations.length > 1 ? 2 : undefined}
            >
              {familiarity.title.replace('{name}', nickName)}
            </Typography>
            <RadioGroup
              value={familiarityId}
              onChange={(e) => setFamiliarityId(e.target.value)}
              sx={{ mt: 1 }}
            >
              {familiarity.options.map((x, i) => (
                <FormControlLabel
                  key={`familiarity${i}`}
                  value={x.id}
                  control={<Radio />}
                  label={x.title}
                />
              ))}
            </RadioGroup>
          </>
        )}
        <Button
          sx={{
            mt: 1,
            px: 2,
            alignSelf: 'start'
          }}
          type='button'
          onClick={submit}
          disabled={
            (relations &&
              relations.length > 1 &&
              relation === '' &&
              !skipRelations) ||
            (familiarity &&
              !familiarity.optional &&
              familiarityId === '' &&
              !skipFamiliarity)
          }
          variant='contained'
        >
          {t('$Next')}
        </Button>
      </Frame>
    </>
  );
};

export default SurveyRelationship;
