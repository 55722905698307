import { TopicPageType } from '@/enums';
import { Yup, promptValidator } from '@/helpers';
import { Theme } from '@mui/material';

export const fontWeights = Object.freeze({
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
});

export const fontFamilies = Object.freeze({
  Inter: 'Inter',
  Roboto: 'Roboto',
  LibreBaskerville: 'Libre Baskerville'
});

export const tableSrippedRows = (theme: Theme) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[50]
  },
  '&:last-of-type td, &:last-of-type th': {
    '&:first-of-type': {
      borderBottomLeftRadius: '10px'
    },
    '&:last-of-type': {
      borderBottomRightRadius: '10px'
    },
    border: 0
  }
});

export const GRecaptcha = '6LfiRQUoAAAAALo7n579jLIJscc3sP0JQlukyvjg';

export const Benefits: IBenefit[] = [
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'Secure'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'GDPRCompliant'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'EasyToCustomize'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'FullyPersonalizedReports'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'ComprehensizeAndDetailed'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'AdvancedAnalytics'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'MultiLanguages'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'BestInClassGenerativeAI'
  },
  {
    icon: 'fa-solid fa-head-side-mask',
    text: 'FlexibleSubscriptions'
  }
];

export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DiagnoseValidation = () =>
  Yup.object()
    .shape({
      prompt: Yup.string()
        .required('Required')
        .test(
          promptValidator(
            'allItems',
            'options',
            'issue',
            'suggestionCount',
            'seniority'
          )
        ),
      suggestionCount: Yup.number().integer().min(0),
      predefinedChallenges: Yup.array(Yup.string())
        .optional()
        .default(undefined)
    })
    .optional()
    .default(undefined);

export const OptionsValidation = (root?: string) =>
  Yup.array(
    Yup.object().shape({
      title: Yup.string().required('Required'),
      value: Yup.number().required('Required')
    })
  ).when(`$${root || ''}pageType`, {
    is(value: TopicPageType) {
      return (
        value !== TopicPageType.SINGLE && value !== TopicPageType.OPEN_ENDED
      );
    },
    then(schema) {
      return schema.min(1);
    }
  });

export const AvailableLanguages: {
  code: Languages;
  name: string;
}[] = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'fr',
    name: 'Français'
  },
  {
    code: 'it',
    name: 'Italiano'
  },
  {
    code: 'de',
    name: 'Deutsch'
  },
  {
    code: 'es',
    name: 'Español'
  },
  {
    code: 'pt',
    name: 'Português'
  }
];

export const chatGptModels = [
  {
    name: 'GPT-4o',
    children: [
      {
        name: 'GPT-4o',
        value: 'gpt-4o',
        pricing: {
          in: 5,
          out: 15
        }
      },
      {
        name: 'GPT-4o 2024-05-13',
        value: 'gpt-4o-2024-05-13',
        pricing: {
          in: 5,
          out: 15
        }
      }
    ]
  },
  {
    name: 'GPT-3.5 Turbo',
    children: [
      {
        name: 'GPT-3.5 0125',
        value: 'gpt-3.5-turbo-0125',
        pricing: {
          in: 0.5,
          out: 1.5
        }
      },
      {
        name: 'GPT-3.5 Instruct',
        value: 'gpt-3.5-turbo-instruct',
        pricing: {
          in: 1.5,
          out: 2
        }
      }
    ]
  }
];

export const defaultGptModel = 'gpt-4o';
