import { TopicThemes } from '@/enums';
import { ImpactTheme } from '@/helpers/theme';
import { ThemeProvider } from '@mui/material';
import { Default, Impact } from '@survey/feedback/open-ended/themes';

interface IProps {
  topic: SurveyToResponseResponse | TopicSurveyResponse;
  project?: string;
  code?: string;
  itemId: string;
  optionId: string;
  topicId?: string;
  isFeedback: boolean;
  name?: string;
  submit: Func1<CreateSurveyRespondItemBody[], Promise<void>>;
  theme?: TopicThemes;
}

const OpenEnded = ({ theme, ...props }: IProps) => {
  if (theme === TopicThemes.IMPACT) {
    return (
      <ThemeProvider theme={ImpactTheme}>
        <Impact {...props} />
      </ThemeProvider>
    );
  }

  return <Default {...props} />;
};

export default OpenEnded;
